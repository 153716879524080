import * as React from "react";
import { IconProps } from "..";

const ArrowRight = React.forwardRef<SVGSVGElement, IconProps>(
  ({ title = "ArrowRight", titleId = "-arrow-right", ...props }, svgRef) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
          d="M3.75 12h16.5M13.5 5.25L20.25 12l-6.75 6.75"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);
export default ArrowRight;
